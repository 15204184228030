import React from "react"
import { graphql, Link, navigate } from "gatsby"

import titleCase from '../js/titleCase';

import Layout from "../layouts/layout"
import API from '../js/api';
import SEO from "../components/SEO/index.js"
import PostItem from "../components/Blog/PostItem"
import BreadCrumbs from "../components/BreadCrumbs"
import Native from "../components/Native"
import Paging from "../components/Paging"
import Node from "../components/Node"

class Vectors extends React.Component {
  constructor(props){
    super(props);
    let slug = "unset";
    let term = "unset";
    let page = "unset";
    if(typeof document != "undefined"){
      let pathname = document.location.pathname;
      let searchQ = document.location.search;
      if(searchQ.includes("?q=cache")){
        if(typeof window != "undefined"){
          navigate("/vectors/" + searchQ.split(":https://www.pngrepo.com/collection/")[1].split("+")[0]);
        }
      }
      if(pathname){
        slug = pathname.split("/")[2].replace(/\+/g, "-").toLowerCase();
        term = pathname.split("/")[2].replace(/\+/g, " ").replace(/ /g, "-");
        page = pathname.split("/")[3];
        if(!page){
          page = 1;
        }
      }
    }

    this.state = {
      page, term, slug,
      results: Array.apply(null, Array(50)),
      vectorsLoading: true
    }
  }

  componentDidMount(){
    this.fetchData(this.state.term, this.state.page);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let pathname = this.props.location.pathname;

      let slug = "unset";
      let term = "unset";
      let page = "unset";
      if(pathname){
        slug = pathname.split("/")[2].replace(/\+/g, "-").toLowerCase();
        term = pathname.split("/")[2].replace(/\+/g, " ").replace(/ /g, "-");
        page = pathname.split("/")[3];
        if(!page){
          page = 1;
        }
      }
      this.setState({slug, term, page, vectorsLoading: true});
      this.fetchData(term, page);
    }
  }

  fetchData(term, page){
    if(typeof window != "undefined"){
      fetch(`https://api.svgrepo.com/collection/?term=${term}&limit=${50}&start=${(page-1)*50}`).then(res => res.json()).then(res => {
        this.setState({
          results: res.icons,
          count: res.count,
          vectorsLoading: false
        })
      })
    }
  }

  render() {
    let title = titleCase(this.state.term.replace(/-/g, " "));
    if(title == "Unset"){
      title = "!#-title-term-#!";
    }

    let page = this.state.page;
    if(page == "unset"){
      page = "!#-page-#!";
    }

    let slug = this.state.slug;
    if(slug == "unset"){
      slug = "!#-search-slug-#!";
    }

    return (
      <Layout>
        <BreadCrumbs
          links={[
            {title: "Home", link: "/"},
            {title: API.get("collectionsPretty"), link: `/${API.get("collectionsUrlPath")}/all/`},
            {title: title }
          ]}
        />
        <h1 className="title">{API.get("collectionTitle", [title])} {this.state.page != 1 && <span>Page {page}</span>}</h1>
        <p className="description">
          {API.get("collectionDescription", [title, this.state.count ? this.state.count : 50])}
        </p>
        <Native id={"native-js-collection-top-" + page}/>

        <div className="nodeListing">
          {this.state.results.map((res, i) => {
            return <Node key={res ? res.id : i} vector={res} loading={this.state.vectorsLoading}/>
          })}
        </div>
        <Native id={"native-js-collection-bottom-" + page}/>

        {!this.state.results.length &&
          <div className="nothing">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="#666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="bevel"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>

            <strong>Can't find any vector fitting this criteria!</strong>
            <span>Try to search for more simple/less keywords or try more general keywords.</span>
          </div>
        }
        <Paging listing={"collection"} page={page} slug={slug} type={""} count={this.state.count}/>
        <SEO meta={{
          title: API.get("collectionMetaTitle", [title, page]),
          description: API.get("collectionMetaDescription", [title, page]),
          slug: `https://${API.get("domain")}/${API.get("collectionUrlPath")}/${slug}/`,
          breadCrumbs: [{
            to: `/${API.get("collectionsUrlPath")}/all/`,
            title: API.get("collectionsPretty")
          },{
            to: `/${API.get("collectionUrlPath")}/${slug}/`,
            title: `${title} ${API.get("collectionPretty")}`
          }]
        }} />
      </Layout>
    )
  }
}

export default Vectors
